import React from 'react';
import {About, Footer, Header, Skills, Work} from './container';
// add testimonial import section
import {Navbar} from './components';
import './App.scss';

const App = () => {
    return (
        <div className="app">
            <Navbar />
             <Header />
            <About />
            <Work />
            <Skills />
           
            <Footer />
        </div>
    );
}
// Add Testimonial Optional
export default App;
