import React, {useState,useEffect} from 'react';
import './About.scss';
import {images} from '../../constants';
import {motion} from 'framer-motion';
import {urlFor, client} from '../../client';
import {AppWrap, MotionWrap} from '../../wrapper';
// const abouts =[
//     {title: 'Web Development', description:'I am a good developer', imgUrl: images.about01},
//     {title: 'Front end', description:'I am good with React', imgUrl: images.about02},
//     {title: 'IOS Apps', description:'I work on Ios App Development', imgUrl: images.about03}

// ];
// Connected to sanity 
const About = () => {
    const [abouts, setAbouts] = useState([]);
    const [brands,setBrands]=useState([]);
    useEffect(() => {
        const query = '*[_type == "abouts"]';
        client.fetch(query)
        .then((data) => setAbouts(data));
         
    }, []);
    useEffect(()=>{
    const brandsQuery = '*[_type == "brands"]';
    let unmounted = false;

    client.fetch(brandsQuery)
    .then((data) => {
        if(!unmounted){
            setBrands(data)}
        });
    return ()=>{
    unmounted = true; 
    }
        }, []);
    return (
       <>
        
         <div className="app__header-about app__flex" >
         
            <motion.div
            whileInView ={{x:[-100,0], opacity: [0,1]}}
            transition = {{duration: 0.5}}
            className = "app__header-about-info"
            >
                <div className="app__header-about-badge" >
                    <div className="badge-cmp app__flex">
                        
                        <div style={{marginLeft:20}}>
                        
                        Education: <span> California State University, Los Angeles Bachelors in Science:</span><br/> <span>Computer Science</span>

                        </div>
                    </div>
                    <div className="app__about-brand app__flex">
                    {brands.map((brand)=>(
                        <motion.div
                        whileInView= {{opacity: [0,1]}}
                        transition={{duration:0.5, type: 'tweem'}}
                        key={brand._id}
                        >
                        <img src={urlFor(brand.imgUrl)} alt={brand.name}/>
                        </motion.div>
                        ))}
                    </div>
                    <div className="tag-cmp app__flex" >
                            <p >
                            Developing <span>Great Apps</span><br/>means <span>Great Business </span> <br/>and<span> Great Opportunities</span>
  
                            </p>
                           
                    </div>

                </div>
                
            </motion.div>
            </div>
      
      
        
       <div className="app__profiles">
        {abouts.map((about,index) => (
                <motion.div
                    whileInView={{opacity:1}}
                    whileHover={{scale:1.1}}
                    transition={{duration:0.5,type:'tween'}}
                    className="app__profile-item"
                    key={about.title + index}
                >
                <img src={urlFor(about.imgUrl)} alt={about.title}/>
                <h2 className="bold-text" style={{marginTop: 20}}> {about.title}  </h2>
                <h2 className="p-text" style={{marginTop: 10}}> {about.description}  </h2>


                </motion.div>
        
        
        ))}
       </div>
       </>
    );
}

export default AppWrap(
    MotionWrap(About,'app__about'),'about');
        
        
