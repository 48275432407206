import React,  {useState,useEffect} from 'react';
import {motion} from 'framer-motion';
import {HiChevronLeft, HiChevronRight} from 'react-icons/hi';

import {AppWrap,MotionWrap} from '../../wrapper';
import {urlFor, client} from '../../client';
import './Testimonial.scss';
const Testimonial = () => {


    const [brands,setBrands]=useState([]);
    const [testimonial,setTestimonial]=useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    const leftHandleClick = (index) => {

       
        let check = setCurrentIndex(index)
        try {
            if (check === undefined ){
                
                setCurrentIndex(0) 
                console.log ('here 11')
                
            }
            else{
                setCurrentIndex(index)
                console.log("im here")
            }
          } catch (error) {
            console.log("left button error")
          }
        
      
    }
     
    const rightHandleClick = (index) => {

     
        const checkIndex = setCurrentIndex(index);
        try {
            if (checkIndex === undefined ){
                
                checkIndex = checkIndex - 1
                
                
            }
            else{
                setCurrentIndex(index)
            }
          } catch (error) {
            console.log("right button error")
          }
        
      
    }
        
    useEffect(() => {

        const testimonialQuery = '*[_type == "testimonials"]';
        let unmounted = false;

        client.fetch(testimonialQuery)
        .then((data) => {
            if(!unmounted){
                setTestimonial(data)}
            });
          return ()=>{
          unmounted = true; 
          }

        
       
         
    }, []);
    useEffect(() => {
        const brandsQuery = '*[_type == "brands"]';
        let unmounted = false;

        client.fetch(brandsQuery)
        .then((data) => {
            if(!unmounted){
                setBrands(data)}
            });
        return ()=>{
        unmounted = true; 
        }
            }, []);

    const testy = testimonial[currentIndex];
    
    return (
        <>
        { 
            testimonial.length && (
                <>
                    <div className="app__testimonial-item app__flex">
                        <img src={urlFor(testy.imgurl)} alt="testimonial"
                        
                          
                        />
                        <div className="app__testimonial-content ">
                            <p className="p-text">
                                {
                                    testy .feedback
                                }
                            </p>
                            <div>
                                <h4 className="bold-text">{testy.name}</h4>
                                <h5 className="p-text">{testy.company}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="app__testimonial-btns app__flex">
                        <div className="app__flex" onClick={() => leftHandleClick(currentIndex === 0 ? testimonial.length: currentIndex - 1)}>
                                <HiChevronLeft /> 
                        </div>
                        <div className="app__flex" onClick={() => rightHandleClick(currentIndex === testimonial.length - 1 ? 0: currentIndex + 1)}>
                                <HiChevronRight /> 
                        </div>

                    </div>
                </>
            )
        }

        <div className="app__testimonial-brand app__flex">
            {brands.map((brand)=>(
                <motion.div
                    whileInView= {{opacity: [0,1]}}
                    transition={{duration:0.5, type: 'tweem'}}
                    key={brand._id}
                >
                 <img src={urlFor(brand.imgUrl)} alt={brand.name}/>
                 </motion.div>
            ))}
        </div>
        </>
    );
}

export default AppWrap(MotionWrap(Testimonial,'app__testimonial')
    , 'testimonial');

