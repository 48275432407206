import React, {useState,useRef} from 'react';
import './Footer.scss';
import {images} from '../../constants';
import {AppWrap, MotionWrap} from '../../wrapper';
import {client} from '../../client';
import emailjs from '@emailjs/browser';
const Footer = () => {
    const [formData, setFormData] = useState({name:'', email:'', message:''});
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const {name,email,message} = formData;

    const handleChangeInput = (e) =>{
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
        // Review this later react
    }

    const handleSubmit = () => {
        setLoading(true);

        const contact = {
            _type: 'contact',
            name: name,
            email: email,
            message: message
        }

        !name | !email | !message ? setLoading(false)
      
        : client.create(contact).then(()=>{
            setLoading(false);
            setIsFormSubmitted(true);
        })

    }

    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
      

      const {name, email,message} = form.current;

    //   console.log(`Name : ${name.value}, Email : ${email.value}, Message : ${message.value}`);

      !name.value | !email.value | !message.value ? alert('No blank values')
      
      :
      
      emailjs.sendForm(process.env.REACT_APP_YOUR_SERVICE_ID, process.env.REACT_APP_YOUR_TEMPLATE_ID, form.current, process.env.REACT_APP_YOUR_PUBLIC_KEY)
      .then((result) => {
        setLoading(true);
        setIsFormSubmitted(true);
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      setLoading(false);
     
      
  
     
    };

    

    return (
        <>
        <h2 className="head-text"> Feel free to reach out </h2>
        <div className="app__footer-cards">
            <div className="app__footer-card">
                <img src={images.email} alt="email"/>
                <a href="mailto:angel3zdev@gmail.com" className="p-text">Contact Email</a>
            </div>
        </div>


        {!isFormSubmitted ?
        <div className="app__footer-form app__flex">
            <form  ref={form} onSubmit={sendEmail} >
            <div className="app__flex">
                <input className="p-text" type="text" placeholder="Your Name" name="name" value={name} onChange={handleChangeInput}/>
            </div>
            <div className="app__flex">
                <input className="p-text" type="email" placeholder="Your Email" name="email" value={email} onChange={handleChangeInput}/>
            </div>
            <div>
            
                <textarea className="p-text" placeholder="Your Message" value={message} name="message" onChange={handleChangeInput}></textarea>
            
            </div>
            {/* <input type="submit" value="Send" /> */}
            <button type="submit" className="p-text" value="Send" onClick={handleSubmit}  >{loading ? 'Sending' : 'Send'}</button>
            {/* onClick={handleSubmit} */}
            </form>

        </div>
        : <div>
            <h3 className="head-text">Thank you for reaching out!</h3>
        </div>
        }
        </>
    );
}

export default AppWrap(MotionWrap(Footer,'app__footer')
    , 'contact');
