import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import mobile1 from '../assets/mobile1.png';
import api from '../assets/api.png';
import node from '../assets/node.png';
import logo from '../assets/logo.png';
import python from '../assets/python.png';
import react from '../assets/react.png';
import sass from '../assets/sass.png';
import circle from '../assets/circle.svg';
import header from '../assets/header.png';
import android from '../assets/android.png';
import java from '../assets/java.png';
import swift from '../assets/swift.png';
import ios from '../assets/ios.png';
import css from '../assets/css.png';
import git from '../assets/git.png';
import html from '../assets/html.png';
import javascript from '../assets/javascript.png';
import mobileNavBG from '../assets/mobileNavBg.jpg';
import csula from '../assets/csula.jpeg';
export default {
  email,
  mobile,
  mobile1,
  api,
  git,
  html,
  javascript,
  node,
  python,
  react,
  sass,
  css,
  circle,
  logo,
  header,
  android,
  java,
  swift,
  ios,
  mobileNavBG,
  csula
};