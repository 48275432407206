import React from 'react'
import {BsGithub, BsLinkedin} from 'react-icons/bs';
import {FaFacebookF} from 'react-icons/fa'
const SocialMedia = () => {
    return (
        <div className="app__social">
            <div>
                
                <a href="https://github.com/angel3z"><BsGithub/> </a> 
            </div>
            
            <div>
               <a href="https://www.linkedin.com/in/angel-orduño-ab85b17a">  <BsLinkedin/>   </a>    
            </div>
                
        </div>
    )
}

export default SocialMedia
